hr {
    border: none;

    &:before {
        content: "";
        display: block;
        max-width: 100%;
        width: 100%;
        border: 1px solid grey;
    }
}

ul {
    padding-left: 15px;
}

a { 
    color: inherit;
    text-decoration: none;
}