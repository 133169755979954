.x-scroll {
  overflow-x: auto;
}

@media (min-width: 320px) {
  .max-width {
    max-width: 330px;
  }

  .x-scroll {
    display: flex;
  }
}

@media (min-width: 576px) {
  .max-width {
    max-width: 540px;
  }

  .x-scroll {
    display: unset;
  }
}

@media (min-width: 768px) {
  .max-width {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .max-width {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .max-width {
    max-width: 1140px;
  }
}
